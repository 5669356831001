import React from 'react';
import program1 from '../Assets/IMG-20230102-WA0000.jpg';
import program2 from '../Assets/IMG-20230102-WA0005.jpg';
import program3 from '../Assets/IMG_2314.jpg';
import program4 from '../Assets/IMG_2330.jpg';
import './programs.css';

export default function Programs() {
    return (
        <section className="programs" id="programs">
            <div className="section-title">
                <h2>Gym Programs</h2>
            </div>
            <div className="programs-content">
                <div className="content-grid">
                    <div className="content">
                        <img src={program1} alt="" className="program-img"/>
                        <div className="info">
                            <i className="fa-solid fa-dumbbell"></i><br/>
                            <span>Body Building</span>
                            <p>Program aimed at building muscle mass, strength, and definition.
                            Structured resistance training routines targeting major muscle groups.
                            Emphasis on progressive overload to stimulate muscle growth.
                            Incorporation of compound exercises, isolation movements, and various training techniques.
                            Nutrition guidance emphasizing adequate protein intake, calorie surplus, and nutrient timing to support muscle growth and recovery.</p>
                           
                        </div>
                        <img src={program2} alt="" className="program-img"/>
                    </div>
                    <div className="content">
                        <div className="info">
                            <i className="fa-solid fa-heart-circle-bolt"></i><br/>
                            <span>Weight Loss</span>
                            <p>Program aimed at building muscle mass, strength, and definition.
                            Structured resistance training routines targeting major muscle groups.
                            Emphasis on progressive overload to stimulate muscle growth.
                            Incorporation of compound exercises, isolation movements, and various training techniques.
                            Nutrition guidance emphasizing adequate protein intake, calorie surplus, and nutrient timing to support muscle growth and recovery.</p>
                           
                        </div>
                        <img src={program2} alt="" className="program-img"/>
                        <div className="info">
                            <i className="fa-solid fa-utensils"></i><br/>
                            <span>Healthy Meal Plans</span>
                            <p>Customized meal plans tailored to individual goals, preferences, and dietary restrictions.
                            Balanced macronutrient distribution (carbohydrates, proteins, fats) to support health and performance.
                            Portion control guidance to manage calorie intake and promote weight loss or maintenance.
                            Emphasis on whole, nutrient-dense foods, including lean proteins, fruits, vegetables, whole grains, and healthy fats.
                            Flexible and sustainable approach to eating that promotes long-term adherence and lifestyle change.</p>
                            
                        </div>
                    </div>
                    <div className="content">
                        <img src={program1} alt="" className="program-img"/>
                        <div className="info">
                            <i className="fa-solid fa-person-dress"></i><br/>
                            <span>Booty Builder Program</span>
                            <p>Program designed to sculpt and strengthen the muscles of the glutes (buttocks).
                            Targeted resistance exercises focusing on the gluteus maximus, medius, and minimus muscles.
                            Variety of exercises including squats, lunges, hip thrusts, deadlifts, and glute bridges.
                            Progressive overload to continually challenge the muscles for growth and development.
                            Incorporation of different rep ranges, tempos, and exercise variations to stimulate muscle growth and prevent plateaus.</p>
                       
                        </div>
                        <img src={program2} alt="" className="program-img"/>
                    </div>
                </div>
                <div className="pricing">
                    <div className="pricing-intro">
                        <h3>Pricing Plan</h3>
                        <p>Finding The Right Prices And Plan For You</p>
                        <span>Membership Fee <span className="fee">M50.00</span></span>
                    </div>
                    <div className="prices-grid">
                        <div className="prices">
                            <h3>Gym Subscription</h3>
                            <p>M <span>500</span>/month</p>
                            <ul>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Personal Trainer
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Performance Monitoring
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Scheduled Training Regiment
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Anytime Access To Gym Facilities
                                </li>
                                <li>
                                    <i className="fa-regular fa-circle-xmark"></i>
                                    Meal Plan
                                </li>
                            </ul>
                        </div>
                        <div className="prices">
                            <h3>Weight Loss Therapy</h3>
                            <p>M <span>850</span>/month</p>
                            <ul>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Personal Trainer
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Progress Monitoring
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Scheduled Training Regiment
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Basic Nutrition and Lifestyle Training
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Tailored Weightloss Meal Plan
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Psychological Support
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                   Personalized Workout Program
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Personalized Nutrition
                                </li>
                            </ul>
                        </div>
                        <div className="prices">
                            <h3>Booty Builder Program</h3>
                            <p>M <span>650</span>/month</p>
                            <ul>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Personal Trainer
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Progress Monitoring
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Scheduled Training Regiment
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Personalized Nutrition and Lifestyle Training
                                </li>
                                <li>
                                    <i className="fa-regular fa-circle-xmark"></i>
                                    Booty Building Targeted Meal Plan
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    
}