import beforeAfter1 from "../Assets/before-after-01.jpg";
import beforeAfter2 from "../Assets/before-after-02.jpg";
import beforeAfter3 from "../Assets/before-after-03.jpg";
import beforeAfter4 from "../Assets/before-after-04.jpg";
import beforeAfter5 from "../Assets/before-after-05.jpg";
import beforeAfter6 from "../Assets/before-after-06.jpg";
import beforeAfter7 from "../Assets/before-after-07.jpg";
import beforeAfter8 from "../Assets/before-after-08.jpg";
import beforeAfter9 from "../Assets/before-after-09.jpg";

export const Images = [
    {
        id: 1,
        image: beforeAfter1,
    },
    {
        id: 2,
        image: beforeAfter2,
    },
    {
        id: 3,
        image: beforeAfter3,
    },
    {
        id: 4,
        image: beforeAfter4,
    },
    {
        id: 5,
        image: beforeAfter5,
    },
    {
        id: 6,
        image: beforeAfter6,
    },{
        id: 7,
        image: beforeAfter7,
    },
    {
        id: 8,
        image: beforeAfter8,
    },{
        id: 9,
        image: beforeAfter9,
    }
]
