import React from 'react';
import './about.css';
import aboutImg from '../Assets/IMG_2314.jpg';
export default function About(){
    return (
        <section className="about" id="about">
            <div className="section-title">
                <h2><span>About</span> Us</h2>
            </div>
            <div className="section-content">
                <div className="section-img">
                    <img src={aboutImg} alt="" width="450px"/>
                </div>
                <div className="section-information">
                    <h2>Every Day Is A Chance To Become Your Best Self</h2>
                    <p>Our gym includes a team of knowledgeable and dedicated trainers who have extensive knowledge in their respective crafts. Our trainers have gained a series of skills that make them more than qualified for the job. 
                    </p>
                    <div className="info-grid">
                        <div className="info">
                            <span><i className="fa-solid fa-check"></i>Improve Your Health & Well-being</span>
                            <p>One of the most common and effective objectives for working out is to improve your the health and well-being. Regular exercise has been shown to reduce the risk of chronic diseases such as heart disease, diabetes, and obesity, as well as boost mental health and overall well-being.</p>
                        </div>
                        <div className="info">
                            <span><i className="fa-solid fa-check"></i>Increase Your Productivity:</span>
                            <p>Regular exercise has also been shown to increase energy levels, improve cognitive function, and reduce stress, all of which can lead to increased productivity and leave you satified with yourself and your work.</p>
                        </div>
                        <div className="info">
                            <span><i className="fa-solid fa-check"></i>Increase Longevity</span>
                            <p>Exercise helps to maintain a healthy weight by burning calories and building lean muscle mass. Obesity is linked to numerous health problems, including diabetes, hypertension, and certain types of cancer. By managing weight, regular gym attendance can reduce the risk of developing these conditions and increase overall longevity</p>
                        </div>
                        <div className="info">
                            <span><i className="fa-solid fa-check"></i>Step Out With Confidence</span>
                            <p>Regular exercise can lead to improvements in body composition, such as increased muscle tone and decreased body fat. As individuals see positive changes in their physical appearance, they may feel more confident about their bodies and how they present themselves to others.</p>
                        </div>
                    </div>
                    <a href="#" className="about-btn">Read More...</a>
                </div>
            </div>
        </section>
    )
}