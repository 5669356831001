import React from 'react';
import './main.css';
import Header from '../Header/Header';

export default function Main(){
    return(
        <main className="main container" id="home">
            <div className="content">
                <Header/>
                <div className='text'>
                    <h1>Growing In Health<br/> & Fitness Together</h1>
                    <span>
                        <p>
                        New Moon Gym Plus is a fitness facility that is aimed at helping with growth in fitness and in health, while cultivating a community of like-minded individuals. We believe that a healthy lifestyle and a good workout can go a long way. Working out can help increase energy levels, improve morale and indicate a level of self-discipline.
                        </p>
                    </span>
                    <a href="" className="start-btn">Get Started</a>    
                </div>
            </div>
        </main>
    )
}
