import React from "react";
import './testimonials.css';
import { Images } from "./Images";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from "swiper";
import './progress.css'

export default function Progress() {
  return (
    <Swiper className="testimonials-container mySwiper" slidesPerView={2} loop={true} grabCursor={true} 
                spaceBetween={24} autoplay={{ delay: 1500, disableOnInteraction: false }} pagination={{ clickable: true }} 
                breakpoints={{ 360: { slidesPerView: 1}, 480: { slidesPerView: 1}, 576: { slidesPerView: 1}, 786: { slidesPerView: 3}}} 
                modules={ [Pagination, Autoplay] }>
                {
                Images.map(({
                    image,
                    id
                }) => {
                    return (
                        <SwiperSlide className="testimonials-card swiper-slide" key={id}>
                            <div className="slick-slide">
                                <img src={image} className="slide-images"  alt=""/>
                            </div>
                        </SwiperSlide>
                    )
                })
            } </Swiper>

  )
}
