import Image1 from '../Assets/Profile01.jpg';
import Image2 from '../Assets/Profile02.jpg';
import Image3 from '../Assets/Profile03.jpg';


export const Data = [
    {
        id: 1,
        image: Image1,
        title: "User One",
        program: "Weight Loss Therapy",
        description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit dolores natus dolorum repudiandae sint est, aliquam saepe eaque consequuntur provident mollitia nemo sunt cupiditate. Harum iure sapiente dolore velit similique."
    }, {
        id: 2,
        image: Image2,
        title: "User Two",
        program: "Weight Loss Therapy",
        description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit dolores natus dolorum repudiandae sint est, aliquam saepe eaque consequuntur provident mollitia nemo sunt cupiditate. Harum iure sapiente dolore velit similique."
    }, {
        id: 3,
        image: Image3,
        title: "User Three",
        program: "Weight Loss Therapy",
        description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit dolores natus dolorum repudiandae sint est, aliquam saepe eaque consequuntur provident mollitia nemo sunt cupiditate. Harum iure sapiente dolore velit similique."
    }

]
